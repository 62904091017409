import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import About from './About';
import Home from './Home';
import Portfolio from './Portfolio';
import Footer from './Footer';
import Navbar from './Navbar';
import Hamburger from './Hamburger';
function App() {
    return (
        <Router>
            <div style={{ position: 'relative' }}>
                <div className="nav-div">
                    <Navbar />
                </div>
                <div className="content-div">
                    <Switch>
                        <Route path="/nav" component={Hamburger} exact />
                        <Route path="/" component={Home} exact />
                        <Route path="/about" component={About} />
                        <Route path="/portfolio" component={Portfolio} />
                    </Switch>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
