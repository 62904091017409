import React from "react"
// import ReactCanvasConfetti from 'react-canvas-confetti'
 
/*
COMPONENT FOR AWARDS ON ABOUT PAGE
@TODO
  - add confetti on award hover
*/

// function confetti() {
//   confetti({
//     particleCount: 100,
//     spread: 70,
//     origin: { y: 0.6 }
//   });
// }

function AwardTemplate(props) {
  return (
    <div className="awardTemp">
        {/* <div id="confetti" className="awardTemp-div" onMouseEnter={confetti}> */}
        <div id="confetti" className="awardTemp-div">
            <h1 className="awardTemp-icon">{props.icon}</h1>
        </div>
        <div className="awardTemp-div2">
            <h1 className="awardTemp-div2-title">{props.title}</h1>
            <h1 className="awardTemp-div2-desc">{props.description}</h1>
        </div>

    </div>
  )
}
 
export default AwardTemplate;