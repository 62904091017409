import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import menu from "../img/menu.svg";
import resume from "../content/VanessaHoang_Resume.pdf";

export default function Hamburger() {
  let history = useHistory();
  // const location = useLocation();

  const size = useWindowSize();

  if (size.width > 992) {
    history.goBack();
  }
  return (
    <div className="mob">
      <div className="mob-bar">
        <img
          src={menu}
          onClick={history.goBack}
          className="mob-hamburger"
          alt=""
        ></img>
        {/* <div onClick={history.goBack} className="mob-hamburger"></div> */}
      </div>
      <div className="mob-div">
        <p>Vanessa Hoang</p>
        <div className="mob-navbar">
          <NavLink to="/" className="link">
            01. Home
          </NavLink>
          <NavLink to="/about" className="link">
            02. About Me
          </NavLink>
          <NavLink to="/portfolio" className="link">
            03. Portfolio
          </NavLink>
          <a href={resume} target="_blank" rel="noreferrer">
            04. Resume
          </a>
        </div>
      </div>
    </div>
  );
}

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
