import React from "react"
 
/*
COMPONENT FOR AWARDS ON ABOUT PAGE
@TODO
  - add confetti on award hover
*/
function currTemplate(props) {
  return (
    <div className="currTemp">
        <div className="currTemp-div">
            <a href={props.link} target="_blank" rel="noreferrer">
              <img className="currTemp-img" alt="" src={props.img}></img>
            </a>
        </div>
        <div className="currTemp-div2">
            <h1 className="currTemp-div2-title">{props.title}</h1>
            <h1 className="currTemp-div2-desc">{props.description}</h1>
            <h1 className="currTemp-div2-date">{props.date}</h1>
        </div>
    </div>
  )
}
 
export default currTemplate;