import React from 'react';
import { Link } from 'react-router-dom';
import memoji from '../img/vanessamemoji.png';
import menu from '../img/menu.svg';
/*
COMPONENT FOR HOME PAGE
@TODO
- mainly done just make it responsive
*/
function hideImage() {
    document.getElementById('image').style.visibility = 'hidden';
}

function showImage() {
    document.getElementById('image').style.visibility = 'visible';
    document.getElementById('image').style.animation = 'fadeOut .5s';
}

function Home() {
    return (
        <div className="home">
            <div className="mob-bar">
                <Link to="/nav">
                    <img src={menu} className="mob-hamburger" alt=""></img> 
                </Link>
            </div>
            <div className="home-content">
                <div className="home-div">
                    <div className="image-div" id="image">
                        <img src={memoji} alt="vanessa memoji" className="memoji" />
                    </div>
                    <div className="image-div2">
                        <img src={memoji} alt="vanessa memoji" className="memoji2" />
                    </div>
                    <h1 className="welcome-text">Welcome! I am</h1>
                </div>
                <h1 id="name" className="home-name" onMouseOver={showImage} onMouseLeave={hideImage}>
                    Vanessa Hoang
                </h1>
                <div className="home-div2">
                    <h1 className="home-text-2">
                        a Computer Science student with a passion for design and technology attending
                        Ryerson University in Toronto, Canada.
                    </h1>
                </div>
            </div>
        </div>
    );
}

export default Home;