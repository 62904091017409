import React from "react"
import DesignProjectTemp from "./DesignProjectTemplate"
import SHacks from "../img/sHacks.png"
import noise from "../img/noise.png"
import linkup from "../img/linkup.png"
import enqueue from "../img/enqueue.png"
import openbook from "../img/openbook.png"
import personalweb2 from "../img/personalweb2.png"
import personalweb1 from "../img/personalweb.png"
import acnhbot from "../img/acnh.png"
// import ridethesubway from "../img/ridethesubway.png"
import subway from "../img/subway.jpg"

function ProjectTemplate(props) {
  return (
    <div className="featuredProjects">
      <h1 className="featuredProjects-header">{props.header}</h1>
      <div className="featuredProjects-div">
        <DesignProjectTemp
            cover={subway}
            title="ridethesubway"
            tech={<p><span className="codetag">Coding</span> <span>&#8226;</span> HTML, CSS, JavaScript, React, Figma</p>}
            desc="Everyone sure misses commuting! ridethesubway is a fun website that creates an artificial subway ride ambience. 
            Take out your book or headphones and come along for a TTC ride on Line 1."
            link="https://vanessah9.github.io/ridethesubway/"
          />
        <DesignProjectTemp
          cover={noise}
          title="Noise."
          tech={<p><span className="uxtag">UX/UI</span> <span>&#8226;</span> Figma, Photoshop, React, MongoDB, Express, Sass, Typescript</p>}
          desc="An app for new talents to gain exposure in the field or those 
          who are starting a project and looking for local creators to get the job done."
          link="https://www.figma.com/file/H3xQWJt6P4XJ5MUZzfOyvO/NOISE-Final?node-id=0%3A1"
        />
        <DesignProjectTemp
          cover={linkup}
          title="Link Up"
          tech={<p><span className="codetag">Coding</span> <span>&#8226;</span> HTML, Sass, Javascript, PHP, MySQL, Figma</p>}
          desc="A web application to ease the struggles of making friends in an 
          online environment during the pandemic. Complete a simple questionnaire and 
          get matched with people!"
          link=" https://github.com/vanessah9/Link-Up"
        />
        <DesignProjectTemp
          cover={enqueue}
          title="Enqueue"
          tech={<p><span className="uxtag">UX/UI</span> <span>&#8226;</span> Figma</p>}
          desc="Enqueue is the solution to long lines and wait times. Our app decreases 
          your exposure to others and allows you to wait for stores in the comfort of 
          your car or anywhere else you’d like to be."
          link="https://www.figma.com/file/qqcJiESllIS8amSjJlBaqn/enqueue_submission"
        />
        <DesignProjectTemp
          cover={acnhbot}
          title="ACNH Discord Bot"
          tech={<p><span className="codetag">Coding</span> <span>&#8226;</span> Python</p>}
          desc="Explored using API's and JSON files by creating a discord bot using Discord.py 
          API and ACNH RESTful API. This bot was inspired by my recent pastime, Animal Crossing 
          New Horizons, and is created to return the information of the called item 
          (ex. fish, bug, art etc.) found in the database."
          link="https://github.com/vanessah9/ACNH-Discord-Bot"
        />
        <DesignProjectTemp
          cover={openbook}
          title="OpenBook"
          tech={<p><span className="uxtag">UX/UI</span> <span>&#8226;</span> Figma</p>}
          desc="This app is made to ease the process of booking appointments. 
          Users are able to track their appointments, medical history and even well 
          chat with medical professionals for any health inquiries."
          link="https://www.figma.com/file/jSdTAaFcYvcJvRr7dzxJlD/OpenBook-App-2-Mockup"
        />
        <DesignProjectTemp
          cover={SHacks}
          title="Scotia iLearn"
          tech={<p><span className="uxtag">UX/UI</span> <span>&#8226;</span> Figma, JavaScript, HTML, CSS</p>}
          desc="An app that encourages financial literacy within youth. 
          This gamified app offers numerous educational resources provided by Scotiabank 
          along with assessments, as well as a chatbot for finance related questions."
          link="https://www.figma.com/file/ahDboeS5rTVZsp1HCHqt5N/SHacks-2020?node-id=28:143"
        />
        <DesignProjectTemp
          cover={personalweb2}
          title="Personal Website 2.0"
          tech={<p><span className="codetag">Coding</span> <span>&#8226;</span> HTML, Sass, React, Javascript, Figma</p>}
        />
        <DesignProjectTemp
          cover={personalweb1}
          title="Personal Website 1.0"
          tech={<p><span className="codetag">Coding</span> <span>&#8226;</span> HTML, CSS, Figma</p>}
          link="https://vanessah9.github.io/"
        />
      </div>
    </div>
  )
}

export default ProjectTemplate;