import React from "react"
 
/*
COMPONENT FOR PAST EXPERIENCE SECTION ON ABOUT PAGE
*/
function PageTemplate(props) {
  return (
    <div className="pastTemp">
        <div className="pastTemp-div">
            <h1 className="pastTemp-title">{props.title}</h1>
            <h1 className="pastTemp-position">{props.position}</h1>
            <h1 className="pastTemp-desc">{props.description}</h1>
        </div>
    </div>
  )
}
 
export default PageTemplate;