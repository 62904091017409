import React from "react"
 

/*
COMPONENT FOR PAGE HEADINGS
This if for the title and description on each page -> about, portfolio, project
*/

function PageTemplate(props) {
  return (
    <div className="pageTemp">
      <h1 className="pageTemp-title">{props.title}</h1>
      <h1 className="pageTemp-desc">{props.description}</h1>
    </div>
  )
}
 
export default PageTemplate;