import React from 'react';
import PageTemp from './PageTemplate';
import AwardTemp from './AwardTemplate';
import PastTemp from './PastTemplate';
import CurrTemp from './CurrTemp';
import vanessa from '../img/vanessa.jpeg';
import wealthsimple from '../img/wealthsimple.png';
import { Link } from 'react-router-dom';
import menu from '../img/menu.svg';
/*
COMPONENT FOR ABOUT PAGE
@TODO
  - mainly done but make page responsive 
  - add confetti on award hover
*/
function About() {
    return (
        <div className="about">
            <div className="mob-bar">
                <Link to="/nav">
                    <img src={menu} alt="menu icon" className="mob-hamburger"></img> 
                </Link>
            </div>
            <div className="about-pageTemp">
                <PageTemp title="About Me" description="Here’s a little bit about me." />
            </div>
            <div className="about-div">
                <img src={vanessa} alt="Vanessa Hoang" className="vanessaImg"></img>
                <p className="about-div-intro">
                    Hi I'm Vanessa, a{' '}
                    <span style={{ color: '#C7A989' }}>fourth-year Computer Science student </span>
                    minoring in Communications and Design! In my free time, I enjoy attending{' '}
                    <span style={{ color: '#C7A989' }}>hackathons</span>,{' '}
                    <span style={{ color: '#C7A989' }}>workshops </span>
                    and expressing myself through different mediums of art such as{' '}
                    <span style={{ color: '#C7A989' }}>photography </span> and{' '}
                    <span style={{ color: '#C7A989' }}>digital art</span>.
                    <br />
                    <br />
                    Currently I am seeking Winter '23 and Summer '23 Co-op Positions!
                </p>
            </div>
            <div className="about-div2">
                <div className="about-currently">
                    <h1 className="about-heading">Currently</h1>
                    <h1 className="about-desc">What I am up to at the moment</h1>
                    <div className="about-job">
                        <CurrTemp
                            title="Wealthsimple"
                            description="Software Engineer Co-op"
                            date="05.2022 - Present"
                            img={wealthsimple}
                            link="https://www.wealthsimple.com/en-ca"
                        />
                    </div>
                </div>
                <div className="about-achieve">
                    <h1 className="about-heading">Achievements</h1>
                    <h1 className="about-desc">Recent school and hackathon awards</h1>
                    <AwardTemp
                        title="Finlayson Innovation in User Experience Award"
                        description="Ryerson University, 2021"
                        icon="🏆"
                    />
                    <AwardTemp title="Best Design Hack" description="SheHacks V, 2021" icon="🏆" />
                    <AwardTemp
                        title="Dean’s List"
                        description="Ryerson University, 2020-2022"
                        icon="🎓"
                    />
                </div>
                <div className="about-prev">
                    <h1 className="about-heading">Previously</h1>
                    <h1 className="about-desc">Past experiences</h1>
                    <PastTemp
                        title="RU Hacks"
                        position="UX/UI Designer"
                        description="06.2021 - 06.2022"
                    />
                    <PastTemp
                        title="CIBC"
                        position="Software Developer Intern"
                        description="05.2021 - 08.2021"
                    />
                    <PastTemp
                        title="Ryerson Developer Student Club"
                        position="VP of Marketing"
                        description="08.2020 - 08.2021"
                    />
                    <PastTemp
                        title="Ryerson Women in Computer Science"
                        position="Events & Marketing Associate"
                        description="05.2020 - 05-2021"
                    />
                </div>
            </div>
        </div>
    );
}

export default About;
