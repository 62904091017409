import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
// import About from './About';
import { useLocation } from 'react-router-dom';
import resume from "../content/VanessaHoang_Resume.pdf";


function Navbar() {
    const location = useLocation();
    console.log(location.pathname);

    const p = location.pathname;
    const [hover, setHover] = useState(false);
    const [hoverItem, setHoverItem] = useState('/');

    function renderEmoji() {
        const pages = {
            '/about': (
                <li id="img2" className="emoji-about">
                    👩
                </li>
            ),
            '/resume': (
                <li id="img4" className="emoji-resume">
                    📄
                </li>
            ),
            '/portfolio': (
                <li id="img3" className="emoji-portfolio">
                    💻
                </li>
            ),
        };
        if (hover) {
            if (hoverItem in pages) {
                return pages[hoverItem];
            }
            return (
                <li id="img1" className="emoji-home">
                    🏠
                </li>
            );
        }
        if (p in pages) {
            return pages[p];
        }
        return (
            <li id="img1" className="emoji-home">
                🏠
            </li>
        );
    }
    return (
        <div className="navbar">
            <ul className="navbar-list">
                <div className="emoji">{renderEmoji()}</div>
                <li
                    onMouseEnter={() => {
                        setHover(true);
                        setHoverItem('/');
                    }}
                    onMouseLeave={() => {
                        setHover(false);
                    }}
                >
                    <NavLink to="/" className="link">
                        01. Home
                    </NavLink>
                </li>
                <li
                    onMouseEnter={() => {
                        setHover(true);
                        setHoverItem('/about');
                    }}
                    onMouseLeave={() => {
                        setHover(false);
                    }}
                >
                    <NavLink to="/about" className="link">
                        02. About Me
                    </NavLink>
                </li>
                <li
                    onMouseEnter={() => {
                        setHover(true);
                        setHoverItem('/portfolio');
                    }}
                    onMouseLeave={() => {
                        setHover(false);
                    }}
                >
                    <NavLink to="/portfolio" className="link">
                        03. Portfolio
                    </NavLink>
                </li>
                <li
                    onMouseEnter={() => {
                        setHover(true);
                        setHoverItem('/resume');
                    }}
                    onMouseLeave={() => {
                        setHover(false);
                    }}
                >
                    <a
                        href={resume}
                        target="_blank"
                        rel="noreferrer"
                    >
                        04. Resume
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Navbar;
