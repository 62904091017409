import React from "react"
import github from "../img/github.png";
import email from "../img/email.png";
import linkedin from "../img/linkedin.png";


function Footer() {
  return (
    <div className="footer">
      <div className="footer-content">
        <h1 className="footer-smalltext">let's get in touch</h1>
        <div className="footer-content-icon">
          <a href="https://www.linkedin.com/in/vanessahoang9/" target="_blank" rel="noreferrer">
            <div className="footer-icon">
              <img src={linkedin} id="linkedin-icon" alt=""/>
            </div >
          </a>
          <a href = "mailto: vanessahoang9@gmail.com" target="_blank" rel="noreferrer">
            <div className="footer-icon">
              <img src={email} id="email-icon" alt=""/>
            </div>
          </a>
          <a href="https://github.com/vanessah9" target="_blank" rel="noreferrer">
            <div className="footer-icon">
            <img src={github} id="github-icon" alt=""/>
            </div>
          </a>
        </div>
        <h1 className="footer-largetext">copyright 2021 &#169; vanessa hoang</h1>
      </div>
    </div>
  )
}
 
export default Footer;