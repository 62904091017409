import React from "react"
// import CodingProjects from './CodingProjects';
// import DesignProjects from './DesignProjects';
import ProjectTemp from "./ProjectTemplate"
 
/*
COMPONENT FOR ALL PROJECTS SECTION
This will consist of the coding and design components
*/
function AllProjects(props) {
  return (
    <div className="allProjects">
        <div className="allProjects-div">
            <h1 className="allProjects-header">{props.header}</h1>
            {/* <CodingProjects header="Coding"/> */}
            <ProjectTemp header="Featured Projects"/>
        </div>
    </div>
  )
}
 
export default AllProjects;