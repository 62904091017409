import React from "react"

 /*
COMPONENT FOR DESIGN PROJECTS
*/
function DesignProjectTemplate(props) {
  return (
    <div className="designTemp">

      <article className="designTemp-card">      
        <a href={props.link} target="_blank" alt="" rel="noreferrer">
            <img src={props.cover} className="designTemp-card-cover" alt=""></img>
            <div className="designTemp-card-text">
                <h1 className="designTemp-card-text-title">{props.title}</h1>
                <h1 className="designTemp-card-text-tech">{props.tech}</h1>
                <h1 className="designTemp-card-text-desc">{props.desc}</h1>
            </div>
          </a>
        </article>
    </div>
  )
}
 
export default DesignProjectTemplate;