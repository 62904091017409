// import React, { useState } from 'react';
import React from 'react';
import { Link} from 'react-router-dom';
import AllProjects from './AllProjects';
import PageTemp from './PageTemplate';
import menu from '../img/menu.svg';

/*
COMPONENT FOR PORTFOLIO PAGE
*/
function Portfolio() {
    // const [pageView, setpageView] = useState(0);
    return (
        <div className="portfolio">
            <div className="mob-bar">
                <Link to="/nav">
                    <img src={menu} className="mob-hamburger" alt=""></img> 
                </Link>
            </div>
            <div className="portfolio-pageTemp">
                <PageTemp
                    title="Portfolio"
                    description="A gallery of my recent projects and work."
                />
            </div>
            {/* <div className="portfolio-links">
                <a href="#"><li>All</li></a>
                <li onClick={() => setpageView(0)}>Coding</li>
                <li onClick={() => setpageView(1)}>Design </li>
            </div> */}
            <div className="portfolio-div">
                <div className="allProjects">
                    <div className="allProjects-div">
                        <AllProjects/>
                        {/* {pageView === 0 ? (
                            <CodingProjects header="Coding" />
                        ) : (
                            <DesignProjects header="Design" />
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Portfolio;
